import jQuery from 'jquery'
import ionRangeSlider from "ion-rangeslider"
import AutoNumeric from "autonumeric"

window.$ = window.jQuery = jQuery

$(function() {
  let itemPrice = '125000'
  let itemPriceFormat = itemPrice.replace(/\s/g, "")
  let rateBase = 0.05 / 12
  let $calcResult = $("#calcResult span")

  let $selfDeposit = $("#selfDeposit")
  let $financingPeriod = $("#financingPeriod")
  let $vehiclePurchase = $("#vehiclePurchase")

  let $selfDepositValue = $("#selfDepositValue span")
  let $financingPeriodValue = $("#financingPeriodValue")
  let $vehiclePurchaseValue = $("#vehiclePurchaseValue span")

  let selfDepositInstance
  let financingPeriodInstance
  let vehiclePurchaseInstance

  let $formValue = $("#wartosc-maszyny")
  let $formDeposit = $("#wplata-wlasna")
  let $formFinancing = $("#okres-finansowania")
  let $formPurchase = $("#wykup-pojazdu")
  let $formRate = $("#orientacyjna-rata")

  $("#itemValue").on('cut copy paste', function(e) {
    e.preventDefault();
  })

  $.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.search);
    return (results !== null) ? results[1] || 0 : false;
  }

  if ($.urlParam('wartosc')) {

    if ($.urlParam('wartosc') > 1000000) {
      $("#itemValue").val(1000000)
      itemPriceFormat = itemPrice = 1000000
    } else {
      $("#itemValue").val($.urlParam('wartosc'))
      itemPriceFormat = itemPrice = $.urlParam('wartosc')
    }
  } else {
    $("#itemValue").val(itemPrice)
  }

  let $itemVal = new AutoNumeric('#itemValue', {
    currencySymbol: " zł",
    currencySymbolPlacement: "s",
    emptyInputBehavior: 'min',
    //maximumValue: "1000000",
    minimumValue: "1",
    decimalPlaces: 0,
    digitGroupSeparator: ' ',
    onInvalidPaste: 'clamp'
  })

  $formValue.val($itemVal.rawValue + ' zł')

  $selfDeposit.ionRangeSlider({
    skin: "round",
    postfix: '%',
    onStart: function(data) {
      $selfDepositValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (data.from/100)))
      $formDeposit.val(data.from + "%")
    },
    onChange: function(data) {
      $selfDepositValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (data.from/100)))
      $calcResult.html(rateCalc(itemPriceFormat, data.from, vehiclePurchaseInstance.old_from, financingPeriodInstance.old_from))
      $formRate.val($calcResult.html().replace(/&nbsp;/g, ' '))
      $formDeposit.val(data.from + "%")
      switch (true) {
        case (data.from >= '45') && ((financingPeriodInstance.old_from == '2') || (financingPeriodInstance.old_from == '3')):
          vehiclePurchaseInstance.update({
            max: '30'
          })
          break
        case (data.from < 45) && (financingPeriodInstance.old_from == '2'):
          vehiclePurchaseInstance.update({
            max: '50'
          })
          break
        case (data.from < 45) && (financingPeriodInstance.old_from == '3'):
          vehiclePurchaseInstance.update({
            max: '40'
          })
          break
        default:
          break
      }
    }
  })
  selfDepositInstance = $selfDeposit.data("ionRangeSlider")

  $financingPeriod.ionRangeSlider({
    skin: "round",
    onStart: function(data) {
      if (data.from > 4) {
        $financingPeriodValue.html(data.from + ' lat')
        $formFinancing.val(data.from + ' lat')
      } else {
        $financingPeriodValue.html(data.from + ' lata')
        $formFinancing.val(data.from + ' lata')
      }

    },
    onChange: function(data) {
      $calcResult.html(rateCalc(itemPriceFormat, selfDepositInstance.old_from, vehiclePurchaseInstance.old_from, data.from))
      $formRate.val($calcResult.html().replace(/&nbsp;/g, ' '))
      if (data.from > 4) {
        $financingPeriodValue.html(data.from + ' lat')
        $formFinancing.val(data.from + ' lat')
      } else {
        $financingPeriodValue.html(data.from + ' lata')
        $formFinancing.val(data.from + ' lata')
      }
      switch (true) {
        case (data.from == '2') && (selfDepositInstance.old_from < 45):
          vehiclePurchaseInstance.update({
            from: '19',
            min: '19',
            max: '50'
          })
          break
        case (data.from == '2') && (selfDepositInstance.old_from >= 45):
          vehiclePurchaseInstance.update({
            min: '19',
            max: '30'
          })
          break
        case (data.from == '3') && (selfDepositInstance.old_from < 45):
          vehiclePurchaseInstance.update({
            min: '1',
            max: '40'
          })
          break
        case (data.from == '3') && (selfDepositInstance.old_from >= 45):
          vehiclePurchaseInstance.update({
            min: '1',
            max: '30'
          })
          break
        case data.from == '4':
          vehiclePurchaseInstance.update({
            min: '1',
            max: '30'
          })
          break
        case data.from == '5':
          vehiclePurchaseInstance.update({
            min: '1',
            max: '25'
          })
          break
        case (data.from == '6') || (data.from == '7'):
          vehiclePurchaseInstance.update({
            from: '1',
            min: '1',
            max: '1'
          })
          break
        default:
          break
      }
    }
  })
  financingPeriodInstance = $financingPeriod.data("ionRangeSlider")

  $vehiclePurchase.ionRangeSlider({
    skin: "round",
    postfix: '%',
    onStart: function(data) {
      $vehiclePurchaseValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (data.from/100)))
      $formPurchase.val(data.from + '%')
    },
    onChange: function(data) {
      $calcResult.html(rateCalc(itemPriceFormat, selfDepositInstance.old_from, data.from, financingPeriodInstance.old_from))
      $vehiclePurchaseValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (data.from/100)))
      $formPurchase.val(data.from + '%')
      $formRate.val($calcResult.html().replace(/&nbsp;/g, ' '))
    },
    onUpdate: function(data) {
      $calcResult.html(rateCalc(itemPriceFormat, selfDepositInstance.old_from, data.from, financingPeriodInstance.old_from))
      $vehiclePurchaseValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (data.from/100)))
      $formPurchase.val(data.from + '%')
      $formRate.val($calcResult.html().replace(/&nbsp;/g, ' '))
    }
  })
  vehiclePurchaseInstance = $vehiclePurchase.data("ionRangeSlider")

  function rateCalc(p, sd, vp, t) {
    sd = (p * (sd/100))
    vp = (p * (vp/100))
    let rateValue = ((p - sd)-(vp / (Math.pow((1 + rateBase), (t*12))))) / ((1 - (1 / (Math.pow((1 + rateBase), (t*12))))) / rateBase)
    return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(rateValue)
  }

  $("#itemValue").on('keyup change paste', function(e) {
    let v = $itemVal.rawValue
    let min = parseInt(10000)
    let max = parseInt(1000000)

    itemPriceFormat = v

    if (v < min){
      $('#price-valid').html('Minimalna wartość pojazdu powinna wynosić 10 000zł').show()
    } else if (v > max){
      $('#price-valid').html('Maksymalna wartość pojazdu nie może przekraczać 1 000 000zł').show()
      $itemVal.rawValue = '1000000'
      $("#itemValue").val('1 000 000 zł')
      itemPriceFormat = '1000000'
      $calcResult.html(rateCalc(itemPriceFormat, selfDepositInstance.old_from, vehiclePurchaseInstance.old_from, financingPeriodInstance.old_from))
      $selfDepositValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (selfDepositInstance.old_from/100)))
      $vehiclePurchaseValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (vehiclePurchaseInstance.old_from/100)))
      $formRate.val($calcResult.html().replace(/&nbsp;/g, ' '))
      $('#price-valid').delay(4000).hide(0)
    } else if (v >= min) {
      $('#price-valid').hide()
      $calcResult.html(rateCalc(itemPriceFormat, selfDepositInstance.old_from, vehiclePurchaseInstance.old_from, financingPeriodInstance.old_from))
      $selfDepositValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (selfDepositInstance.old_from/100)))
      $vehiclePurchaseValue.html(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumSignificantDigits: 3 }).format($itemVal.rawValue * (vehiclePurchaseInstance.old_from/100)))
      $formRate.val($calcResult.html().replace(/&nbsp;/g, ''))
    }

    $formValue.val($itemVal.rawValue + ' zł')
  })

  $calcResult.html(rateCalc(itemPriceFormat, selfDepositInstance.old_from, vehiclePurchaseInstance.old_from, financingPeriodInstance.old_from))
  $formRate.val($calcResult.html().replace(/&nbsp;/g, ' '))

})